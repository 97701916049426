import { Route } from '@angular/router';
import { AppComponent } from './app.component';

/**
 * An array of Route objects that define the routes for the application.
 */
export const appRoutes: Route[] = [

    // Auth routes for guests or users
    {
        path: '',
        canActivate: [],
        canActivateChild: [],
        component: AppComponent,
    }
];
