import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApplicationConfig, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withPreloading, PreloadAllModules, withInMemoryScrolling } from '@angular/router';
import { appRoutes } from './app.routes';

/**
 * Application configuration object.
 */
export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(withFetch()),
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),
        ),
        provideExperimentalZonelessChangeDetection()
    ],
};

