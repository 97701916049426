
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { environment } from '@env/environment';
import { enableProdMode } from '@angular/core';

console.log('Running in environment:', environment.name);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
    .catch(err => console.error(err));